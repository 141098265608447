import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { navigate } from "@reach/router"
import { Text, Title, Button } from "gatsby-theme-material-foundry"
import { Container, makeStyles, Grid, Box,  useMediaQuery,
  ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core"
import SEO from "../components/SEO/seo"
import SVGHero from "../components/SvgHero"
import { NewsSvg } from "../components/svgs"
import { useTheme } from "@material-ui/styles"
import { FilterButtonList } from "../components/filter-button"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: "200px",
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: "500",
  },
  hero: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "150px",
      paddingBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "250px",
      paddingBottom: "100px",
    },
  },
  svg: {
    width: "200px",
  },
  morelink: {
    fontWeight: 400,
    marginLeft: "10px",
    textDecoration: "none",
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.dark,
      textDecoration: "underline",
    },
  },
}))

function NewsPage({ data }) {
  const classes = useStyles()
  const theme = useTheme()
  const [news, setNews] = React.useState(data.allNyheter.nodes)
  const [filter, setFilter] = React.useState({campus: ""})

  return (
    <Layout dark>
      <SEO postTitle="Siste nytt"/>
      <SVGHero
        title="Nyheter"
        subtitle="Les om det siste som har skjedd på HVL Skape"
      >
        <NewsSvg
          fill1={theme.palette.success.main}
          fill2={theme.palette.primary.main}
          fill3="#e2e2ec"
          fill4={theme.palette.black.main}
          className={classes.svg}
        />
      </SVGHero>
      <Box mb={8}>
        <Box mb={4}>
        {useMediaQuery(theme.breakpoints.up('sm')) ? 
        <FilterButtonList
          filterNames={["", "Bergen", "Haugesund", "Sogndal", "Stord", "Førde"]}
          setEvents={setNews}
          setFilter={setFilter}
          data={data}
          filter={filter}
        />
        : (<ExpansionPanel>
           <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Text black>Filtrer nyheter</Text>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <FilterButtonList
          filterNames={["", "Bergen", "Haugesund", "Sogndal", "Stord", "Førde"]}
          setEvents={setNews}
          setFilter={setFilter}
          data={data}
          filter={filter}
        />
        </ExpansionPanelDetails>
        </ExpansionPanel>)}
        </Box>
        <Container maxWidth="md">
          <Grid container>
            {news.map(n => (
              <Grid item>
                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item sm={4}>
                      <img
                        src={`${n.coverUrl}&sz=w500`}
                        alt={`${n.tittel}`}
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "cover",
                          objectPosition: "100% 50%",
                        }}
                      />
                    </Grid>
                    <Grid item sm={8}>
                      <Title
                        variant="h5"
                        component={Link}
                        to={`/nyheter/${n.slug}`}
                        secondary
                      >
                        {n.tittel}
                      </Title>
                      <Box mb={2}>
                        <Text black className={classes.subtitle}>
                          {n.dato}
                        </Text>
                      </Box>
                      <Text>
                        {n.blurb}...
                        <Link
                          className={classes.morelink}
                          to={`/nyheter/${n.slug}`}
                        >
                          les mer{" "}
                        </Link>
                      </Text>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
              {news.length < 1 && (
          <Container align="center" maxWidth="sm">
            <Box pt={8}>
              <Text black variant="h5" component="p">
                Det er dessverre ikke noen nyheter i {filter.campus}. For mer info hva som skjer:
              </Text>
              <Button
                color="primary"
                onClick={() => navigate("/om-oss#kontakt-oss")}
              >
                Ta kontakt
              </Button>
            </Box>
          </Container>
        )}
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}
export const NewsPageQuary = graphql`
  query newsPageQuary {
    allNyheter(sort: { order: DESC, fields: dato }) {
      nodes {
        tittel
        slug
        tekst
        blurb
        coverUrl
        dato(locale: "nb", formatString: "dddd, Do MMMM YYYY")
        forfatter
        campus
      }
    }
  }
`

export default NewsPage
