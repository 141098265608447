import React from "react"
import { DividedSection, Title, Text } from "gatsby-theme-material-foundry"
import { Container, makeStyles, Box, Hidden } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontWeight: 600,
  },
  hero: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "150px",
      paddingBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "250px",
      paddingBottom: "100px",
    },
  },
  svg: {
    width: "200px",
  },
}))

export default function SvgHero({ title, subtitle, children }) {
  const classes = useStyles()
  return (
    <Container maxWidth="md">
      <DividedSection className={classes.hero}>
        <Box>
          <Title variant="h2" primary>
            {title}
          </Title>
          <Text black variant="h5" className={classes.subtitle}>
            {subtitle}
          </Text>
        </Box>
        <Hidden xsDown>
          <Container align="center">{children}</Container>
        </Hidden>
      </DividedSection>
    </Container>
  )
}
